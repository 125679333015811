.product-images-slider {
    height: 400px; /* Adjust the height as per your requirement */

    // .swiper-slide {
        padding-top: 0; /* Remove the padding */
        // height: 100%; /* Set the height to 100% */

        img {
            width: 300; /* Adjust the image width */
            height: 100%; /* Set the image height to 100% */
        }
    // }

    .swiper-button-prev {
        left: 0;
        color: #000;
    }

    .swiper-button-next {
        right: 0;
        color: #000;
    }
}

.product-images-slider-thumbs {
    height: auto; /* Remove the fixed height */

    .swiper-slide {
        cursor: pointer;
        border: 1px solid #dddbdb;
        width: calc(25% - 10px); // Set the width of each thumbnail to 25% of the container width
        height: auto; // Set the height to auto to maintain aspect ratio
        max-height: 100px; // Set the maximum height of the thumbnail
        max-width: 100px; // Set the maximum width of the thumbnail
        display: flex;
        justify-content: center;
        align-items: center;

        &-thumb-active {
            border-color: #ff0000;
        }
    }

    &-wrapper {
        width: 100%;
        padding-top: 0; /* Remove the padding */
        overflow: hidden;
        position: relative;

        img {
            width: auto; // Allow the image width to adjust dynamically
            height: 100%; // Set the height to 100% to ensure full visibility
            object-fit: contain; // Maintain aspect ratio and fit the image within its container
        }
    }
}
