.table-container {
    @apply rounded-lg overflow-x-auto w-full;
}
.thead-tr {
    @apply text-left bg-gray-50;
}
.th {
    @apply p-4 uppercase text-xs font-bold text-gray-600 text-left;
}
.td {
    @apply p-4 text-sm text-gray-700;
}
.dashboard-table {
    @apply w-full bg-gray-900 rounded-md;
}
.dashboard-tr {
    @apply border-b border-gray-800 text-left; border-bottom:  0.5px solid white
}
.dashboard-th {
    @apply p-3 uppercase text-sm font-medium text-gray-500;text-align: center;border: 0.5px solid white ;
}
.dashboard-td {
    @apply p-3  text-sm font-normal text-gray-400; border-left: 0.5px dashed white ;border-bottom: 0.5px dashed white ;text-align: center;
}