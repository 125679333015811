/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import "./styles/buttons.css";
@import "./styles/form.css";
@import "./styles/styles.css";
@import "./styles/nav.css";
@import "./styles/header.css";
@import "./styles/table.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }

  .headerSedenavLi {
    @apply: underline underline-offset-2 cursor-pointer text-gray-300 hover: text-white;
  }
}

body {
  font-family: "Jost", sans-serif;
}


* {
  font-family: "Jost", sans-serif;
}

a{
  text-decoration: none !important;
}


.brandcard img {
  height: 200px;
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: black;
  font-weight: 600;
}

.duin {
  height: 420px;
  padding: 10px;
  background-color: #f8f8f8;
}

@media (max-width: 991px) {
  .duin {
    height: 220px;
    padding: 5px;
    background-color: #f8f8f8;
  }
}

.product-mainimg{
  object-fit: cover;
}

.groups {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);

}

.top-header {
  background-color: #0171bc;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-family: "Jost", sans-serif;
}

.numbers {
  color: white;
}

.hu {
  color: black;
}

.top-header H6 {
  text-align: center;
  margin-bottom: 0PX;
}


.fuckman {
  padding: 20px 0px;
}

@media screen and (max-width: 665px) {
 
.fuckman {
  padding: 0px 0px;
}

}

@media (max-width: 991px) {
  .desktop-only {
    display: none;
  }
}
.max-w-container {
  max-width: 1400px; /* Adjust as per your design */
  margin-left: auto;
  margin-right: auto;
}

.fuckman h3 {
  text-transform: uppercase;
}

.heightincrease {
  height: 200px;
}

.soccer {
  border-radius: 50%;
  height: 100%;
  height: 250px;
  border: 6px solid #0171bc;
}

.schoolsoccer {
  border: 2px solid #0171bc;
  height: 100%;
}

/* Categories.css */
.categories-swiper .swiper-slide {
  height: 200px; /* Default height for desktop */
  margin-bottom: 10px;
}

@media (max-width: 640px) {
  .categories-swiper .swiper-slide {
    height: 100px; /* Height for mobile */
  }
}


.feature-block-wrapper img {
  margin: 0px auto;
}

ul {
  margin-bottom: 0px !important;
  font-family: "Jost", sans-serif;
}

.fontstyleclass {
  font-family: "Jost", sans-serif;

}

@media screen and (max-width: 665px) {
  .fontstyleclass {
    font-family: "Jost", sans-serif;
  font-size: 20px;
  }
}


.home-tag {
  text-decoration: none;
  font-family: "Jost", sans-serif;
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
    font-family: "Jost", sans-serif;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}



.shopping-cart__totals-wrapper .sticky-content {
  /* padding-top: 3.125rem; */
}
.sticky-content {
  position: sticky;
  top: 0;
  padding-bottom: 1px;
}

@media (min-width: 1200px){
.shopping-cart__totals {
  width: 26.25rem;
}
}

.shopping-cart__totals {
  border: 1px solid #e1e1e1;
  margin-bottom: 1.25rem;
  padding: 20px;
  max-width: 100%;
  border-radius: 5px;
}

.shopping-cart__totals>h3, .shopping-cart__totals>.h3 {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}

.shopping-cart__totals table {
  width: 100%;
}

.shopping-cart__totals table th {
  text-transform: uppercase;
  vertical-align: baseline;
}
.shopping-cart__totals table th, .shopping-cart__totals table td {
  border-bottom: 1px solid #e4e4e4;
  padding: 0.875rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}



.shopping-cart__totals table th, .shopping-cart__totals table td {
  border-bottom: 1px solid #e4e4e4;
  padding: 0.875rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}
thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}


.checkout-form .checkout__totals-wrapper .sticky-content {
  padding-top: 3.125rem;
}
.sticky-content {
  position: sticky;
  top: 0;
  padding-bottom: 1px;
}
.checkout__totals {
  border: 1px solid #222;
  margin-bottom: 1.25rem;
  padding: 2.5rem 2.5rem 0.5rem;
  width: 100%;
}
.checkout-form .checkout__payment-methods {
  border: 1px solid #e4e4e4;
  margin-bottom: 1.25rem;
  padding: 2.5rem 2.5rem 1.5rem;
  width: 100%;
}

.checkout-form .checkout__totals-wrapper .sticky-content {
  padding-top: 3.125rem;
}

.sticky-content {
  position: sticky;
  top: 0;
  padding-bottom: 1px;
}

.checkout__totals table {
  width: 100%;
}

.checkout_totals .checkout-totals th{
  border-bottom: 1px solid #e4e4e4;
  padding: 0.875rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}

.checkout_totals .checkout-totals th, .checkout_totals .checkout-totals td {
  border-bottom: 1px solid #e4e4e4;
  padding: 0.875rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}

.sticky {
  position: sticky;
  top: 120px;
}
.content {
  height: auto;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #000;
  border-bottom: solid 1px #eee;
}
.nav .active {
  background: #000;
  color: #fff;
}


.account-list-title {
  @apply block ml-3 font-medium text-sm text-gray-700 capitalize;
}

.account-list {
  @apply flex items-center px-3 py-3.5 rounded-md;
}

.product-wrapper .product-info .product-title a {
  display: block;
  font-size: 13px !important;
  height: 34px;
  font-family: "Jost", sans-serif;
  overflow: hidden;
}
.product-wrapper .product-image .woocommerce-LoopProduct-link {
  display: block;
  width: 100%;
  height: 207px;
  font-family: "Jost", sans-serif;
  border-bottom: solid 2px #00523b;
}
.e-hover-image-zoom .product-wrapper .product-image img {
  width: 100%;
  height: 100%;
  font-family: "Jost", sans-serif;
  object-fit: contain;
}
.prod .product-wrapper .product-image .woocommerce-LoopProduct-link {
  display: block;
  width: 100%;
  height: 186px;
  font-family: "Jost", sans-serif;
  border-bottom: solid 2px #00523b;
}
.e-image-bg-light .product-wrapper .product-image {
  background-color: transparent;
}
@media (max-width: 768px){
  .product-wrapper .product-image .woocommerce-LoopProduct-link {
      display: block;
      width: 100%;
      height: 174px;
      font-family: "Jost", sans-serif;
      border-bottom: solid 2px #00523b;
  }
  .prod .product-wrapper .product-image .woocommerce-LoopProduct-link {
      display: block;
      width: 100%;
      height: 178px;
      font-family: "Jost", sans-serif;
      border-bottom: solid 2px #00523b;
  }
  .brandcard img {
    /* height: 150px !important; */
    object-fit: cover !important;
}
.h-\[200px\]{
  height: 160px !important;
}
}


.form-label{
  color: black;
  font-size: medium;
  font-family: "Jost", sans-serif;
}


#qty_selector {
  width: 95px;
}

#qty_display {
    border-width: 0px;
    border-color: white;
    background-color:transparent;
    width: 35%;
    margin:0px 10px;
    /* padding-left: 5px */
}
    #qty_display:focus {
        outline: none;
    }
    i:hover, a:hover {
        background-color: transparent;
    }
#qty_select {
    border: solid #0d6efd;
    border-width: 1px 1px;
    border-radius:10px;
}

    #qty_select:hover {
        border-width:2px 2px;
        box-shadow: 0 0 10px 2px rgba(13, 110, 253, 0.2);
    }



    .checkoutbutton-container{
      display: flex;
      flex-direction: row;
    justify-content: space-between;
    }

    .checkoutbutton-container .checkoutbutton{
     margin-right: 5px;
    }

    .checkoutbutton-container .buynowbutton{
     margin-left: 5px;
    }

    @media (max-width: 768px){
      .checkoutbutton-container{
        display: flex;
        flex-direction: column;
      
      }
      .checkoutbutton-container .checkoutbutton{
        margin-bottom: 5px;
       }
   
       .checkoutbutton-container .buynowbutton{
        margin-top: 5px;
       }
    }
   /* ============= NavLink active End here ================ */
.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.brandcard {
  padding: 10px; /* Adjust padding as needed */
  width: 6rem; /* Mobile width */
  height: 6rem; /* Mobile height */
}

@media (min-width: 768px) { /* Apply for medium screens and above */
  .brandcard {
    width: 12rem; /* Desktop width */
    height: 12rem; /* Desktop height */
  }
}

.group img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image is contained within the container */
}

.group .mt-2 {
  margin-top: 8px; /* Adjust margin as needed */
}

.group .px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.group .text-black {
  color: black;
}



.cardrow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
}
@media only screen and (min-width: 768px) {
  .large-12 {
      width: 100%;
  }
}

.row .row {
  width: auto;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 0;
  margin-bottom: 0;
  max-width: none;
}
.column, .columns {
  position: relative;
  padding-left: 2px;
  padding-right: 0px;
  width: 100%;
  float: left;
}

.grade-section.large-3.nasa-col.columns {
  border: 3px solid #7ebc7a;
  border-radius: 18px;
  margin: 15px;
}



@media only screen and (min-width: 768px) {
  .large-3:nth-of-type(n) {
      clear: none;
  }
}

@media only screen and (min-width: 768px) {
  .large-3 {
      width: 25%;
  }
}
.vc_btn3-container.grade-bottun.vc_btn3-center {
  margin-top: -25px;
}
.vc_btn3.vc_btn3-color-success.vc_btn3-style-modern {
  color: #fff;
  border-color: #6ab165;
  background-color: #6ab165;
}
.vc_btn3.vc_btn3-size-lg {
  font-size: 16px;
  padding-top: 10px;
}
.vc_btn3.vc_btn3-size-lg {
  font-size: 16px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 25px;
  padding-right: 25px;
}
.vc_btn3.vc_btn3-shape-rounded {
  border-radius: 5px;
}
.vc_btn3.vc_btn3-style-modern {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .01) 100%);
  background-repeat: repeat-x;
}
.vc_general.vc_btn3 {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  background-color: transparent;
  color: #5472d2;
  border: 1px solid transparent;
  box-sizing: border-box;
  word-wrap: break-word;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  position: relative;
  top: 0;
  transition: all .2s ease-in-out;
  line-height: normal;
  box-shadow: none;
  font-size: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
}
.vc_btn3-container.vc_btn3-center {
  text-align: center;
}
.vc_do_btn {
  margin-bottom: 22px;
}
.vc_btn3-container {
  display: block;
  margin-bottom: 21.73913043px;
  max-width: 100%;
}
.wpb_single_image.vc_align_center {
  text-align: center;
}
.wpb_single_image .vc_figure {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  max-width: 100%;
}
.wpb_single_image .vc_single_image-wrapper {
  display: block !important;
}
.wpb_single_image img {
  height: auto;
  max-width: 100%;
      border-radius: 10px;
  vertical-align: top;
}
a img {
  border: none;
}


.grade-section-2.large-3.nasa-col.columns {
  border: 3px solid #a086ce;
  border-radius: 18px;
  margin: 15px;
}
.vc_btn3-container.grade-bottun.vc_btn3-center {
  margin-top: -25px;
}

.vc_btn3-container.vc_btn3-center {
  text-align: center;
}
.vc_do_btn {
  margin-bottom: 22px;
}
.vc_btn3-container {
  display: block;
  margin-bottom: 21.73913043px;
  max-width: 100%;
}
.vc_btn3.vc_btn3-color-violet.vc_btn3-style-modern {
  color: #fff;
  border-color: #8d6dc4;
  background-color: #8d6dc4;
}

.vc_btn3.vc_btn3-size-lg {
  font-size: 16px;
  padding-top: 10px;
}
.vc_btn3.vc_btn3-color-violet, .vc_btn3.vc_btn3-color-violet.vc_btn3-style-flat {
  color: #fff;
  background-color: #8d6dc4;
}
.vc_btn3.vc_btn3-size-lg {
  font-size: 16px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 25px;
  padding-right: 25px;
}
.vc_btn3.vc_btn3-shape-rounded {
  border-radius: 5px;
}
.vc_btn3.vc_btn3-style-modern {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .01) 100%);
  background-repeat: repeat-x;
}
.vc_general.vc_btn3 {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  background-color: transparent;
  color: #5472d2;
  border: 1px solid transparent;
  box-sizing: border-box;
  word-wrap: break-word;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  position: relative;
  top: 0;
  transition: all .2s ease-in-out;
  line-height: normal;
  box-shadow: none;
  font-size: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
}

.grade-section-3.large-3.nasa-col.columns {
  border: 3px solid #fe847b;
  border-radius: 18px;
  margin: 15px;
}
.vc_btn3.vc_btn3-color-pink.vc_btn3-style-modern {
  color: #fff;
  border-color: #fe6c61;
  background-color: #fe6c61;
}
.vc_btn3.vc_btn3-size-lg {
  font-size: 16px;
  padding-top: 10px;
}
.vc_btn3.vc_btn3-color-pink, .vc_btn3.vc_btn3-color-pink.vc_btn3-style-flat {
  color: #fff;
  background-color: #fe6c61;
}
.vc_btn3.vc_btn3-size-lg {
  font-size: 16px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 25px;
  padding-right: 25px;
}
.vc_btn3.vc_btn3-shape-rounded {
  border-radius: 5px;
}
.vc_btn3.vc_btn3-style-modern {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .01) 100%);
  background-repeat: repeat-x;
}

/* Add this to your CSS file */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Default to 2 columns */
  gap: 32px; /* Increased space between items */
  padding: 24px; /* Increased padding around grid */
}

.category-item {
  border-width: 6px; /* Thicker border */
  border-color: #0171bc; /* Blue border color */
  border-style: solid; /* Solid border style */
  transition: transform 0.3s; /* Smooth transition for hover effect */
}

.category-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

@media (min-width: 640px) {
  .categories-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for small screens */
  }
}

@media (min-width: 768px) {
  .categories-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for medium screens */
  }
}

@media (min-width: 1024px) {
  .categories-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for large screens */
  }
}
@media(max-width:767px){
  .soccer {
    height: 150px !important;
    
  }
}
